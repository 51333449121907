<template>
  <div id="opcao-busca" class="row">
    <div
      :class="['col-lg-12 col-md-12 col-xs-12 col-sm-12']"
      style="flex: 1 200px"
    >
      <!-- <span class="title">Pesquisa relacionada</span><br> -->
      <div>
        <div class="busca-header">
          <span class="title">Buscas recentes</span>
          <button class="btn btn-link btn-close-bh" @click="closeOverlay()">
            <i class="fa fa-times fa-2x"></i>
          </button>
        </div>
        <ul class="filter-list">
          <li
            v-for="(row, index) in ultimasBuscas"
            :key="index"
            @click="itemClick(row.termo)"
          >
            <a href="javascript:void(0)">
              <i class="fa fa-history"></i>
              {{ row.termo }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="showProduct"
      class="busca-header col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style="display: flex; flex-direction: column; flex: 1 60%"
    >
      <div class="busca-header">
        <span class="title">Produtos sugeridos</span>
        <button class="btn btn-link btn-close-bh" @click="closeOverlay()">
          <i class="fa fa-times fa-2x"></i>
        </button>
      </div>
      <div
        v-if="showProduct && !loadingPreview"
        style="
          display: flex;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-flow: row wrap;
          flex-wrap: wrap;
          justify-content: flex-start;
        "
      >
        <ItemProduto
          v-for="(item, index) in produtos"
          :key="index"
          :item="item"
          :visualizacao="visualizacao"
          :use-default-view-type="true"
          :show-item-qty-btn="false"
          @closeOverlay="closeOverlay()"
        ></ItemProduto>
      </div>
      <div v-else class="col-lg-12 col-md-12 loading-preview">
        <div v-for="(value, index) in 4" :key="index" class="skeleton-wrapper">
          <div class="skeleton-wrapper-inner">
            <div class="skeleton-wrapper-body">
              <div class="skeleton-slider-item-produto"></div>
              <div class="skeleton-content-1"></div>
              <div class="skeleton-content-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import ItemProduto from "@/components/produtos/ItemProduto";
import * as lastSearch from "@/services/cookie/lastSearch";

export default {
  name: "OpcaoBusca",
  components: { ItemProduto },
  props: {
    searchType: String,
    busca: String,
    ultimasBuscas: Array,
  },
  data() {
    return {
      produtos: {
        type: Array,
        default: Array,
      },

      controla_busca: true,
      showProduct: false,
      loadingPreview: false,
    };
  },
  computed: {
    visualizacao() {
      const windowWidth = window.innerWidth;

      if (windowWidth < 720) {
        return "lista-1";
      }

      return "bloco-1";

      // const modoVisualizacao = this.$store.getters.modoVisualizacao;
      // if (!modoVisualizacao) {
      //   this.$store.dispatch("setModoVisualizacao", "bloco-1");
      // }

      // return modoVisualizacao;
    },
  },
  watch: {
    busca(termo) {
      this.debouncer(termo, this);
      this.showProduct = false;
    },
  },
  methods: {
    itemClick(descricao) {
      this.$router.push({
        name: "catalogoRota",
        query: {
          busca: descricao,
          visualizacao: this.visualizacao,
          ordenacao: "ordem-0",
        },
      });
      this.$emit("closeOverlay");
    },
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    buscaProdutos(termo) {
      termo = termo.trim();
      if (termo.length > 2 && this.controla_busca === true) {
        // BLOQUEIA A EXECUCAO DA CONSULTA
        this.controla_busca = false;

        var vetCodProduto = [];
        var vetEan = [];
        var vetDun14 = [];
        var desc_produto = null;

        // VERIFICANDO SE EXISTE A VÍRGULA QUE USAMOS PARA SEPARAR OS CÓDIGOS
        if (termo.indexOf(",") !== -1) {
          // CASO EXISTA NÓS TIRAMOS OS ESPAÇOS EM BRANCO E TRANFORMAMOS EM UM VETOR
          var vetCodigosTmp = termo.replace(" ", "").split(",");
          var vetCodProdutoTmp = [];
          var vetEanTmp = [];
          var vetDun14Tmp = [];

          if (vetCodigosTmp.length > 0) {
            // ABAIXO TESTAMOS SE TODOS OS ITENS SÃO NUMÉRICOS
            var tdN = true;
            $.each(vetCodigosTmp, (i, o) => {
              if (isNaN(o)) {
                tdN = false;
                return false;
              }
              if (o.length > 13) {
                vetDun14Tmp.push(Number(o));
              } else if (o.length > 7) {
                vetEanTmp.push(o);
              } else {
                vetCodProdutoTmp.push(Number(o));
              }
            });

            if (tdN) {
              vetDun14 = vetDun14Tmp;
              vetEan = vetEanTmp;
              vetCodProduto = vetCodProdutoTmp;
            }
          }
        } else {
          if (!isNaN(termo)) {
            if (termo.length > 13) {
              vetDun14.push(Number(termo));
            } else if (termo.length > 7) {
              vetEan.push(termo);
            } else {
              vetCodProduto.push(Number(termo));
            }
          }
        }

        // CASO O VETOR vetCodProduto ESTEJA VAZIO, SIGNIFICA QUE A BUSCA NÃO É POR CÓDIGO.
        if (
          vetCodProduto.length == 0 &&
          vetEan.length == 0 &&
          vetDun14.length == 0
        ) {
          desc_produto = termo;
        }

        const dadosPedido = this.$store.getters.dadosPedido;

        let preco = {};
        if (dadosPedido) {
          preco = {
            pedido_id: dadosPedido.pedidoId,
            cliente_id: dadosPedido.razao,
            tabvnd_id: dadosPedido.tabvnd,
          };
        }

        this.loadingPreview = true;
        dpcAxios
          .connection()
          .post(process.env.VUE_APP_ECOMMERCE + api.env.VIEW_PRODUTO, {
            cod_produto: vetCodProduto,
            ean: vetEan,
            dun14: vetDun14,
            searchterm: desc_produto,
            searchType: this.searchType,
            offset: 0,
            preco,
            limit: 4, // DEFINE A QUANTIDADE DE REGISTROS Q SERAO RETORNADO
            ordenacao: "ordem-0",
            search_origin: "searchbox-preview",
          })
          .then((response) => {
            // SE TIVER ENCONTRADO REGISTROS
            if (response.data.error == 0) {
              var produtos = [];

              $.each(response.data.produtos, (index, value) => {
                produtos.push({
                  ads: value.ads,
                  cod_categoria: value.cod_categoria,
                  cod_produto: value.id,
                  cod_fornecedor: value.cod_fornecedor,
                  descricao: value.descricao,
                  src: value.src,
                  integracao_id: value.integracao_id,
                  ean: value.ean,
                  vlracresc: value.vlracresc,
                  vlrdesconto: value.vlrdesc,
                  valor: value.vlrproduto,
                  vlrstdesonerado: value.vlrstdesonerado,
                  vlrst: value.vlrst,
                  embeantext: value.embeantext,
                  possuiestoque: value.possuiestoque,
                  hasprodbloq: value.hasprodbloq,
                  hascondbloq: value.hascondbloq,
                  vlrbruto:
                    value.vlrproduto +
                    value.vlrst -
                    value.vlrdesc -
                    value.vlrstdesonerado,
                    decim: value.decim,
                    decim_num_casas: value.decim_num_casas
                });
              });

              this.produtos = produtos;
              this.showProduct = true;
              this.loadingPreview = false;
            }
          })
          .catch((e) => {
            console.error(e);
            this.loadingPreview = false;
          });

        // LIBERA NOVAMENTE A EXECUCAO DA CONSULTA
        this.controla_busca = true;
      }
    },
    debouncer: _debounce((termo, self) => {
      self.termo = self.termo;
      self.buscaProdutos(termo);
    }, 200),
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

#opcao-busca {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .busca-header {
    display: flex;
    flex-direction: row;

    .title {
      font-weight: 700;
      color: #555;
      display: block;
      padding: 0.5em 0em;
      font-size: 1.2em;
      flex: 1 0 auto;
    }

    .btn-close-bh {
      flex: 0 1 auto;
      color: #555555;
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
  }
  @media (min-width: 525px) {
    .border {
      border-left: 1px solid #dbdbdb;
    }
  }

  ul {
    list-style-type: none;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 00px;
    margin: 0px;
    padding: 0px;

    li {
      margin: 4px 0px;

      span {
        padding: 0.25em;
        font-size: 0.95em;
        color: #777;
        transition: all 0.3s ease;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span:hover {
        background: #f1f1f1;
      }
    }
  }

  .filter-list {
    li {
      cursor: pointer;
      padding: 4px;

      a {
        color: #717171;
      }

      &:hover {
        background: #eaeaeaa9;
      }
    }

    input {
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.loading-preview {
  display: flex;
  overflow: hidden;
  padding: 0;
}

.skeleton-wrapper {
  background: #fff;
  // border-radius: 4px;
  // -webkit-border-radius: 4px;
  margin: 10px 5px;
  width: 242px;
  height: 240px;
  float: left;
}

.skeleton-wrapper-inner {
  height: 80px;
  padding: 80px;
  position: relative;
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  position: relative;
}
.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}
.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}
div.skeleton-slider-item-produto {
  top: 10px;
  height: 100%;
  left: 10px;
  right: 10px;
}
div.skeleton-content-1 {
  left: 10px;
  right: 10px;
  height: 20px;
}
div.skeleton-content-2 {
  left: 10px;
  width: 100px;
  height: 10px;
}
div.skeleton-content-1 {
  top: 180px;
}
div.skeleton-content-2 {
  top: 210px;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 1200px;
  }
}
@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

#loading-item-produto .slick-next {
  right: -20px;
}

#loading-item-produto .slick-prev {
  left: -20px;
}
</style>
