import _sum from "lodash/sum";
import state from "../../vuex/state";

export const updateStageCampanha = (campanha) => {
  let orderItems = JSON.parse(JSON.stringify(state.carrinhoAtual));

  orderItems = orderItems.map((cart) => {
    cart.qtd_historico = Number(
      campanha?.produtos_anteriores[cart.cod_produto]?.qtd_historico ?? 0
    );
    return cart;
  });

  Object.entries(campanha?.produtos_anteriores ?? {}).forEach(
    ([productId, product]) => {
      if (!orderItems.some((item) => item.cod_produto == productId)) {
        // Product does not exist in orderItems, so add it

        orderItems.push({
          qtd: 0,
          qtd_historico: Number(product.qtd_historico),
          valor: Number(product.vlrproduto),
          cod_produto: product.produto_id,
          integracao_id: product.integracao_id,
          decim: product.decim,
          decim_num_casas: product.decim_num_casas
        });
      }
    }
  );

  let vlrTotalPedido = 0;
  let qtdTotalPedido = 0;

  let vlrTotalPedidoHistorico = 0;
  let qtdTotalPedidoHistorico = 0;

  let campanhaAux = null;

  let valorCreditoDisponivel = 0;

  if (campanha.situacao_credito.length) {
    valorCreditoDisponivel = +campanha.situacao_credito[0].valor_restante;
  } else {
    valorCreditoDisponivel = campanha.valor_max_prod_participantes;
  }

  const gruposAtivacaoStatus = [];

  const percentMaxPerGroup = 100 / campanha.grupos_ativacao.length;

  let percentCampanha = 0;

  let qtdMinProdutosAtivacao = 0;

  let grupoProximoAtivado = {
    id: null,
    percent: 0,
  };

  // calcula os valores totais do pedido

  campanha.grupos_ativacao.forEach((grupo, indexGrupo) => {
    const grupoCodProdutos = grupo.produtos_ativacao.map(
      (produto_ativacao) => produto_ativacao.integracao_id
    );

    const grupoOrderItems = orderItems.filter((orderItem) =>
      grupoCodProdutos.includes(orderItem.integracao_id.toString())
    );

    const grupoOrderItemsQtd = grupoOrderItems.length
      ? _sum(grupoOrderItems.map((orderItem) => Number(orderItem.qtd)))
      : 0;

    const grupoOrderItemsValorTotal = grupoOrderItems.length
      ? _sum(
          grupoOrderItems.map(
            (orderItem) => Number(orderItem.qtd) * Number(orderItem.valor)
          )
        )
      : 0;

    const grupoOrderItemsQtdHistorico = grupoOrderItems.length
      ? _sum(
          grupoOrderItems.map((orderItem) => Number(orderItem.qtd_historico))
        )
      : 0;

    const grupoOrderItemsValorTotalHistorico = grupoOrderItems.length
      ? _sum(
          grupoOrderItems.map(
            (orderItem) =>
              Number(orderItem.qtd_historico ?? 0) * Number(orderItem.valor)
          )
        )
      : 0;

    if (valorCreditoDisponivel != null) {
      valorCreditoDisponivel -= grupoOrderItemsValorTotal;
    }

    vlrTotalPedido += grupoOrderItemsValorTotal;
    qtdTotalPedido += grupoOrderItemsQtd;

    vlrTotalPedidoHistorico += grupoOrderItemsValorTotalHistorico;
    qtdTotalPedidoHistorico += grupoOrderItemsQtdHistorico;
  });

  // console.log("valorCreditoDisponivel", valorCreditoDisponivel);
  // console.log("vlrTotalPedido", vlrTotalPedido);
  // console.log("qtdTotalPedido", qtdTotalPedido);

  // calcula por grupo de ativacao
  campanha.grupos_ativacao.forEach((grupo, indexGrupo) => {
    qtdMinProdutosAtivacao = 0;

    let qtdProdutosAtivados = 0;
    let vlrProdutosAtivados = 0;

    grupo.produtos_ativacao.forEach((produto_ativacao) => {
      produto_ativacao.qtd_unidades_min = parseFloat(
        produto_ativacao.qtd_unidades_min
      );
      produto_ativacao.qtd_unidades_max = parseFloat(
        produto_ativacao.qtd_unidades_max
      );

      let orderItem = orderItems.find(
        (orderItem) => orderItem.integracao_id == produto_ativacao.integracao_id
      );

      if (!orderItem) {
        orderItem = {
          qtd: 0,
          valortotal: 0,
          valor: 0,
          qtd_historico: 0,
        };
      }

      const prodRules = {
        qtdMin: false,
        qtdMax: false,
        vlrMin: false,
        vlrMax: false,
      };
      if (
        orderItem.qtd + orderItem.qtd_historico >=
          produto_ativacao.qtd_unidades_min ||
        produto_ativacao.qtd_unidades_min == null
      ) {
        prodRules.qtdMin = true;
      }

      if (
        orderItem.qtd + orderItem.qtd_historico <=
          produto_ativacao.qtd_unidades_max ||
        produto_ativacao.qtd_unidades_max == null
      ) {
        prodRules.qtdMax = true;
      }

      if (
        (orderItem.valor &&
          orderItem.valor * (orderItem.qtd + orderItem.qtd_historico) >=
            produto_ativacao.valor_minimo) ||
        produto_ativacao.valor_minimo == null
      ) {
        prodRules.vlrMin = true;
      }

      if (
        (orderItem.valor &&
          orderItem.valor * (orderItem.qtd + orderItem.qtd_historico) <=
            produto_ativacao.valor_maximo) ||
        produto_ativacao.valor_maximo == null
      ) {
        prodRules.vlrMax = true;
      }

      if (Object.values(prodRules).every((e) => e) === true) {
        if (!produto_ativacao.qtd_unidades_min > 0) {
          qtdProdutosAtivados += orderItem.qtd + orderItem.qtd_historico;
        } else {
          qtdProdutosAtivados += produto_ativacao.qtd_unidades_min;
        }
      } else if (produto_ativacao.qtd_unidades_min > 0) {
        qtdProdutosAtivados += orderItem.qtd + orderItem.qtd_historico;
      }

      vlrProdutosAtivados +=
        orderItem.valor * (orderItem.qtd + orderItem.qtd_historico);

      if (produto_ativacao.qtd_unidades_min > 0) {
        qtdMinProdutosAtivacao += produto_ativacao.qtd_unidades_min;
      } else {
        qtdMinProdutosAtivacao =
          grupo.qtd_un_grp_ati_min != null ? +grupo.qtd_un_grp_ati_min : null;
      }
    });

    const rules = [
      vlrTotalPedido + vlrTotalPedidoHistorico >=
        campanha.valor_min_prod_participantes ||
        campanha.valor_min_prod_participantes == null,
      vlrTotalPedido + vlrTotalPedidoHistorico <=
        campanha.valor_max_prod_participantes ||
        campanha.valor_max_prod_participantes == null,
      qtdTotalPedido + qtdTotalPedidoHistorico >=
        campanha.qtd_min_prod_participantes ||
        campanha.qtd_min_prod_participantes == null,
      qtdTotalPedido + qtdTotalPedidoHistorico <=
        campanha.qtd_max_prod_participantes ||
        campanha.qtd_max_prod_participantes == null,
      qtdProdutosAtivados >= qtdMinProdutosAtivacao ||
        qtdMinProdutosAtivacao == null,
      qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max ||
        grupo.qtd_un_grp_ati_max == null,
      vlrProdutosAtivados >= grupo.valor_minimo || grupo.valor_minimo == null,
      vlrProdutosAtivados <= grupo.valor_maximo || grupo.valor_maximo == null,
    ];

    // console.log(
    //   "vlrTotalPedido + vlrTotalPedidoHistorico >= campanha.valor_min_prod_participantes",
    //   vlrTotalPedido + vlrTotalPedidoHistorico >=
    //     campanha.valor_min_prod_participantes,
    //   "vlrTotalPedido + vlrTotalPedidoHistorico <= campanha.valor_max_prod_participantes",
    //   vlrTotalPedido + vlrTotalPedidoHistorico <=
    //     campanha.valor_max_prod_participantes,
    //   "qtdTotalPedido + qtdTotalPedidoHistorico >= campanha.qtd_min_prod_participantes",
    //   qtdTotalPedido + qtdTotalPedidoHistorico >=
    //     campanha.qtd_min_prod_participantes,
    //   "qtdTotalPedido + qtdTotalPedidoHistorico <= campanha.qtd_max_prod_participantes",
    //   qtdTotalPedido + qtdTotalPedidoHistorico <=
    //     campanha.qtd_max_prod_participantes,
    //   "qtdProdutosAtivados >= qtdMinProdutosAtivacao",
    //   qtdProdutosAtivados >= qtdMinProdutosAtivacao,
    //   "qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max",
    //   qtdProdutosAtivados <= grupo.qtd_un_grp_ati_max,
    //   "vlrProdutosAtivados >= grupo.valor_minimo",
    //   vlrProdutosAtivados >= grupo.valor_minimo,
    //   "vlrProdutosAtivados <= grupo.valor_maximo",
    //   vlrProdutosAtivados <= grupo.valor_maximo
    // );
    const followRules = rules.every((e) => e);
    const newRuleStatus = followRules ? "Ativado" : "Desativado";

    // console.log(campanha, followRules);
    // console.log(
    //   "vlrTotalPedido",
    //   vlrTotalPedido,
    //   "campanha.valor_min_prod_participantes",
    //   campanha.valor_min_prod_participantes,
    //   "campanha.valor_max_prod_participantes",
    //   campanha.valor_max_prod_participantes,
    //   "qtdTotalPedido",
    //   qtdTotalPedido,
    //   "campanha.qtd_min_prod_participantes",
    //   campanha.qtd_min_prod_participantes,
    //   "campanha.qtd_max_prod_participantes",
    //   campanha.qtd_max_prod_participantes,
    //   "qtdProdutosAtivados",
    //   qtdProdutosAtivados,
    //   "grupo.qtd_un_grp_ati_max",
    //   grupo.qtd_un_grp_ati_max,
    //   "vlrProdutosAtivados",
    //   vlrProdutosAtivados,
    //   "grupo.valor_minimo",
    //   grupo.valor_minimo,
    //   "grupo.valor_maximo",
    //   grupo.valor_maximo,
    //   "qtdMinProdutosAtivacao",
    //   qtdMinProdutosAtivacao,
    //   "grupo",
    //   grupo
    // );
    grupo.statusAtivacao = newRuleStatus;
    campanha.grupos_ativacao[indexGrupo].statusAtivacao = newRuleStatus;

    const qtdGruposAtivos = campanha.grupos_ativacao.filter(
      (e) => e.statusAtivacao == "Ativado"
    ).length;

    const bonusAtivos = [];
    campanha.grupos_bonus.forEach((grupo_bonus) => {
      // console.log(
      //   "qtdGruposAtivos >= grupo_bonus.qtd_grupos_ativacao && valorCreditoDisponivel > 0",
      //   [
      //     qtdGruposAtivos,
      //     grupo_bonus.qtd_grupos_ativacao,
      //     valorCreditoDisponivel,
      //   ]
      // );

      if (
        qtdGruposAtivos >= grupo_bonus.qtd_grupos_ativacao &&
        (valorCreditoDisponivel > 0 || valorCreditoDisponivel == null)
      ) {
        grupo_bonus.statusAtivacao = "Ativado";

        bonusAtivos.push({
          id: grupo_bonus.id,
          percent: Number(grupo_bonus.vlrdesconto),
          qtdCombos: grupo_bonus.qtd_grupos_ativacao,
          isActive: true,
          displayDiscount: grupo_bonus.display_discount,
        });
      } else {
        grupo_bonus.statusAtivacao = "Desativado";

        bonusAtivos.push({
          id: grupo_bonus.id,
          percent: Number(grupo_bonus.vlrdesconto),
          qtdCombos: grupo_bonus.qtd_grupos_ativacao,
          isActive: false,
          displayDiscount: grupo_bonus.display_discount,
        });
      }
    });

    let percentQtdMinimaGrupo =
      qtdProdutosAtivados >
      Number(campanha.qtd_min_prod_participantes) + qtdMinProdutosAtivacao
        ? 100
        : (qtdProdutosAtivados * 100) /
          ((Number(campanha.qtd_min_prod_participantes) > 0
            ? Number(campanha.qtd_min_prod_participantes)
            : 1) +
            qtdMinProdutosAtivacao);
    percentQtdMinimaGrupo = (50 / 100) * (percentQtdMinimaGrupo / 100) * 100;

    let percentVlrMinimoGrupo =
      vlrProdutosAtivados >
      (grupo.valor_minimo != null ? +grupo.valor_minimo : 0)
        ? 100
        : (vlrProdutosAtivados * 100) /
            (grupo.valor_minimo != null ? +grupo.valor_minimo : 0) || 0;
    percentVlrMinimoGrupo = (50 / 100) * (percentVlrMinimoGrupo / 100) * 100;

    const percentGrupoAtual = percentQtdMinimaGrupo + percentVlrMinimoGrupo;

    const percentGrupoGeral =
      (percentMaxPerGroup / 100) * (percentGrupoAtual / 100) * 100;
    percentCampanha += percentGrupoGeral;

    gruposAtivacaoStatus[grupo.id] = {
      name: `Combo ${indexGrupo + 1} ${grupo.descricao
        .toLowerCase()
        .replace("combo", "")}`,
      qtdMinProd: qtdMinProdutosAtivacao || 1,
      qtdMaxProd: grupo.qtd_un_grp_ati_max,
      qtdMinGrupo: Number(campanha.qtd_min_prod_participantes),
      qtdMaxGrupo: Number(campanha.qtd_max_prod_participantes),
      qtdMinProdAtivados: qtdProdutosAtivados,
      vlrMinProd: grupo.valor_minimo != null ? +grupo.valor_minimo : null,
      vlrMaxProd: grupo.valor_maximo != null ? +grupo.valor_maximo : null,
      vlrMinProdAtivados: vlrProdutosAtivados,
      percent: percentGrupoAtual,
      vlrAtualProd: vlrTotalPedido + vlrTotalPedidoHistorico,
      qtdAtualProd: qtdTotalPedido + qtdTotalPedidoHistorico,
    };

    if (
      ((percentGrupoAtual >= grupoProximoAtivado.percent &&
        percentGrupoAtual != 100) ||
        grupoProximoAtivado.percent == 100) &&
      grupoProximoAtivado.id == null
    ) {
      grupoProximoAtivado = {
        id: grupo.id,
        percent: percentGrupoAtual,
      };
    }

    campanhaAux = {
      id: campanha.id,
      vlrMaxCredito: +campanha.valor_max_prod_participantes,
      vlrRestanteCredito:
        valorCreditoDisponivel != null
          ? valorCreditoDisponivel > 0
            ? valorCreditoDisponivel
            : 0
          : null,
      bonusAtivos,
      gruposAtivacaoStatus,
      percentAtual: percentCampanha,
      grupoProximoAtivado,
      bannerSrc: "https://img.dpcnet.com.br/banners/MONANGE-DESKTOPP.png",
    };
  });
  return campanhaAux;
};
