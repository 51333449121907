import * as dpcAxios from "@/dpcAxios";
import store from "@/vuex";
import bus from "@/utils/events/bus";

const pedidoBusca = {
  methods: {
    async buscaPedidoAberto(params) {
      if (this.$route.name === "carrinhoRota") return;

      let paramsAux = {};

      if (Object.entries(params).length > 0) {
        paramsAux = {
          ...paramsAux,
          ...params,
        };
      }
      try {
        const response = await dpcAxios
          .connection()
          .post(
            `${
              process.env.VUE_APP_ECOMMERCE + api.env.EDICAO_PEDIDO_EC
            }ultimo-pedido`,
            paramsAux
          );

        if (response.data.error == 0) {
          const {
            carrinho_compartilhado,
            permiteAlterarPermissaoFinalizarPedido,
            carrinhoNaoPodeSerCompartilhado,
            cliente,
            condpgto,
            empresa,
            formpgto,
            id,
            tabvnd,
            itens,
          } = response.data.pedido;

          const informacoesPedido = {
            condicaoPagamento: condpgto.id,
            formaPagamento: formpgto.id,
            tipoPagamento: formpgto.tipo,
            pedidoId: id,
            tabvnd: tabvnd.id,
            unidade: empresa.id,
            razao: cliente.id,
            deliveryAddress: cliente.deliveryAddress,
            carrinho_compartilhado,
            permiteAlterarPermissaoFinalizarPedido,
            carrinhoNaoPodeSerCompartilhado,
            informacoes: {
              nomerazao: cliente.nomerazao,
              document: cliente.document,
              unidade: empresa.nomerazao,
              condicaoPagamento: condpgto.descricao,
              formaPagamento: formpgto.descricao,
              tabvnd: tabvnd.descricao,
            },
            status: {
              tabvnd: tabvnd.status,
              condicaoPagamento: condpgto.status,
              formaPagamento: formpgto.status,
            },
          };

          // Disparando dados do pedido
          store.dispatch("setDadosPedido", informacoesPedido);

          // Zerando dados do carrinho
          const carrinhoAtual = "shouldCleanCart"; // Variável de controle para limpar carrinho
          store.dispatch("addItemCarrinhoAtual", carrinhoAtual);

          // Mapeando itens e adicionando ao carrinho
          const itensCarrinho = [];

          itens.map((item) => {
            const itemProcessado = {
              cod_produto: item.produto.id,
              integracao_id: item.produto.integracao_id,
              qtd: item.qtdpedida,
              descricao: item.produto.descricao,
              src: item.produto.src,
              pedido_id: id,
              vlrdesconto: item.vlrdesconto,
              vlrstdesonerado: item.vlrstdesonerado,
              valor: item.vlrproduto,
              vlrst: item.vlrst,
              status: item.produto.status,
              desconto_campanha: item.desconto_campanha,
              campanhasAtivas: item.campanhasAtivas,
              decim: item.decim,
            decim_num_casas: item.decim_num_casas,
              busca: 1, // definindo que há uma busca sendo feita através do carrinho (funcao editar)
            };

            store.dispatch("addItemCarrinhoAtual", itemProcessado);
          });

          localStorage.setItem("pedidoaberto", id);
        } else {
          localStorage.removeItem("pedidoaberto");
          this.$store.dispatch("setDadosPedido", []);
          this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        localStorage.removeItem("pedidoaberto");
        this.$store.dispatch("setDadosPedido", []);
        this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
      }
    },
  },
};

export default pedidoBusca;
