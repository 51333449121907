<template>
  <div id="home">
    <mini-banner
      v-if="show_banner_parcelamento"
      :description="'Parcele seus pedidos em até 3 vezes no cartão de crédito'"
      :type-alert="'home'"
    ></mini-banner>

    <section v-for="(componente, index) in myComponents" :key="index">
      <SliderFull
        v-if="componente.imagens_validas.length && componente.tipo_id === 1"
        :sliders="[componente.imagens_validas]"
        :is-loading="isLoading"
      ></SliderFull>
      <loadingSliderFull
        v-if="
          !componente.imagens_validas.length &&
          componente.tipo_id === 1 &&
          isLoading
        "
      />

      <div class="section-all-components">
        <ParceirosSlider
          v-if="componente.imagens_validas.length && componente.tipo_id === 2"
          :sliders="componente.imagens_validas"
          :is-loading="isLoading"
        ></ParceirosSlider>
        <loadingSliderParceiros
          v-if="
            !componente.imagens_validas.length &&
            componente.tipo_id === 2 &&
            isLoading
          "
        />
        <BannerTree
          v-if="
            componente.imagens_validas.length &&
            componente.tipo_id === 3 &&
            verifyIfShowComponent(componente.tipo_visualizacao)
          "
          :data="componente.imagens_validas"
          :show-title="false"
          cols="3"
          rows="1"
          gap="gap-9"
          margin="mt-4 mb-4"
          :login-required="
            componente.tipo_visualizacao == 0 ||
            componente.tipo_visualizacao == 1
          "
          :is-loading="isLoading"
          :rules="[
            { col: 'col-span-1', row: 'row-span-1' },
            { col: 'col-span-1', row: 'row-span-1' },
            { col: 'col-span-1', row: 'row-span-1' },
          ]"
        />
        <loadingBanner
          v-if="
            !componente.imagens_validas.length &&
            componente.tipo_id === 3 &&
            isLoading
          "
        />
        <BannerTree
          v-if="
            componente.imagens_validas.length > 0 &&
            componente.tipo_id === 4 &&
            verifyIfShowComponent(componente.tipo_visualizacao)
          "
          :data="componente.imagens_validas"
          :show-title="false"
          cols="1"
          margin="mt-8 mb-8"
          rows="1"
          :login-required="
            componente.tipo_visualizacao == 0 ||
            componente.tipo_visualizacao == 1
          "
          :rules="[{ col: 'col-span-1', row: 'row-span-1' }]"
        />
        <loadingBanner
          v-if="
            !componente.imagens_validas.length &&
            componente.tipo_id === 4 &&
            isLoading
          "
        />
        <CategoriasSlider
          v-if="
            componente.imagens_validas.length &&
            componente.tipo_id === 5 &&
            verifyIfShowComponent(componente.tipo_visualizacao)
          "
          :data="componente.imagens_validas"
          :titulo="componente.descricao"
        ></CategoriasSlider>
        <LoadingCategoriasSlider
          v-if="
            !componente.imagens_validas.length &&
            componente.tipo_id === 5 &&
            verifyIfShowComponent(componente.tipo_visualizacao) &&
            isLoading
          "
        />

        <ProdutosSlider
          v-if="
            componente.tipo_id === 6 &&
            componente.produtos &&
            componente.produtos.length &&
            verifyIfShowComponent(componente.tipo_visualizacao)
          "
          :titulo="componente.descricao"
          :produtos="[componente.produtos]"
        ></ProdutosSlider>
        <loadingItemProduto
          v-if="
            verifyIfShowComponent(componente.tipo_visualizacao) &&
            componente.tipo_id === 6 &&
            componente.produtos &&
            !componente.produtos.length &&
            isLoadingProducts
          "
        />

        <BannerTree
          v-if="
            componente.imagens_validas.length &&
            componente.tipo_id === 7 &&
            verifyIfShowComponent(componente.tipo_visualizacao)
          "
          :data="componente.imagens_validas"
          :show-title="false"
          cols="2"
          rows="1"
          :login-required="false"
          gap="gap-9"
          margin="mt-8 mb-8"
          :rules="[
            { col: 'col-span-1', row: 'row-span-1' },
            { col: 'col-span-1', row: 'row-span-1' },
          ]"
        />
        <loadingBanner
          v-if="
            !componente.imagens_validas.length &&
            componente.tipo_id === 7 &&
            verifyIfShowComponent(componente.tipo_visualizacao) &&
            isLoading
          "
        />
      </div>
    </section>
  </div>
</template>

<script>
import cookie from "vue-cookie";

import Newsletter from "@/components/newsletter/Newsletter";

import Banner from "@/components/banner/default";
import BannerTree from "@/components/banner/tree";

import SliderFull from "@/components/slider-full/SliderFull";
import loadingSliderFull from "@/components/slider-full/loading/SliderFull";

import ParceirosSlider from "@/components/parceiros-slider/ParceirosSlider";
import loadingSliderParceiros from "@/components/parceiros-slider/loading/Parceiros";

import CategoriasSlider from "@/components/categorias-slider/CategoriasSlider";
import LoadingCategoriasSlider from "@/components/categorias-slider/loading/LoadingCategoriasSlider";

import BannersDestaque from "@/components/banners-destaque/BannersDestaque";
import loadingBanner from "@/components/banners-destaque/loading/Banner";

import ProdutosSlider from "@/components/produtos/ProdutosSlider";
import loadingItemProduto from "@/components/produtos/loading/ItemProduto";

import MiniBanner from "@/components/banner/MiniBanner.vue";

import { db } from "@/services/db/db.js";

export default {
  name: "Home",
  components: {
    SliderFull,
    ParceirosSlider,
    ProdutosSlider,
    BannersDestaque,
    Newsletter,
    loadingSliderFull,
    loadingSliderParceiros,
    loadingBanner,
    loadingItemProduto,
    CategoriasSlider,
    LoadingCategoriasSlider,
    MiniBanner,
    Banner,
    BannerTree,
  },
  data() {
    return {
      screen_atual: "",
      componentes: [],
      screen_xs: 520,
      screen_sm: 768,
      isLoading: true,
      screen_md: 992,
      screen_lg: 1295,
      json_banners_destaque: "",
      json_slider_full: "",
      banners_destaque: [],
      slider_full: [],
      parceiros: [],
      produtoslider: [],
      dadosPedido: false,
      // CLASSES PARA CARREGAMENTO.
      isSliderFullLoading: true,
      isParceirosSliderLoading: true,
      isBanner1Loading: true,
      isSliderLoading: true,
      isLoadingProducts: true,
      show_banner_parcelamento: false,
    };
  },

  computed: {
    pedidoAtual() {
      return this.$store.getters.dadosPedido;
    },
    mybanners_destaque() {
      return this.banners_destaque == undefined ? [] : this.banners_destaque;
    },
    myslider_full() {
      return this.slider_full == undefined ? [] : this.slider_full;
    },
    myparceiros() {
      return this.parceiros == undefined ? [] : this.parceiros;
    },
    mycategories() {
      return this.$store.getters.categorias || [];
    },
    myComponents() {
      return this.componentes;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
  },
  // async beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.getComponents();
  //     next();
  //   });
  // },
  watch: {
    async pedidoAtual(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.dadosPedido = newValue;
      this.getComponents();
    },
  },
  created() {
    this.show_banner_parcelamento =
      process.env.VUE_APP_SHOW_BANNER_PARCELAMENTO != "false";
    this.getComponents();
    // Se existir parametros do pedido no store
    Object.keys(this.$store.getters.dadosPedido).length !== 0
      ? (this.dadosPedido = this.$store.getters.dadosPedido)
      : false;

    window.addEventListener("resize", this.resize);
    this.resize();
  },
  methods: {
    verifyIfShowComponent(tipo_visualizacao) {
      if (tipo_visualizacao == 0 || !tipo_visualizacao) {
        return true;
      }
      if (tipo_visualizacao == 1) {
        return this.isAuthenticated;
      }
      if (tipo_visualizacao == 2) {
        return !this.isAuthenticated;
      }
    },
    async getComponentProdutos(componentesId) {
      // Iniciando variáveis para validação
      let params = {};

      if (this.dadosPedido) {
        params = {
          pedido_id: this.dadosPedido.pedidoId,
        };
      }

      params.componente_id = componentesId;

      try {
        this.isLoadingProducts = true;
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_COMPONENTES_PRODUTOS}`;
        const { data } = await dpcAxios.connection().post(url, params);
        if (!data.error) {
          if (data.componentes_produtos.length > 0) {
            data.componentes_produtos.forEach((componente_produto) => {
              if (componente_produto.produtos.length > 0) {
                const componenteIndex = this.componentes.findIndex(
                  (componente) =>
                    componente.componente_id == componente_produto.componente_id
                );

                if (componenteIndex != -1) {
                  const lista_produtos = [];
                  componente_produto.produtos.map((value) => {
                    if (
                      typeof value.possuiestoque !== "undefined" &&
                      value.possuiestoque == false
                    ) {
                      return;
                    }
                    lista_produtos.push(this.montaVariavelDeProduto(value));
                  });
                  this.componentes[componenteIndex].produtos = lista_produtos;
                  this.componentes[componenteIndex].isLoading = false;

                  const componentesCopy = this.componentes;
                  this.componentes = [];
                  this.componentes = componentesCopy;
                }
              }
            });
          }
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingProducts = false;
      }
    },

    // Busca componentes do site
    async getComponents() {
      // Iniciando variáveis para validação
      let params = {};
      const produtos_componentes = [];

      /**
       * TODO: busca de componentes do indexeddb está causando bug na montagem dos banners. Comentada até que seja resolvido.
       * Para reproduzir, remova um banner e adicione outro, depois limpe o cache do backend e recarregue a página na opção "forçar carregamento e limpar cache".
       *
       */
      // await db.componente.toArray().then((componentes) => {
      //   this.componentes = componentes;
      // });
      if (this.componentes.length > 0) {
        this.isLoading = false;
      }

      if (this.dadosPedido) {
        params.token = this.$store.getters.getToken || cookie.get("token");
        params = {
          preco: {
            cliente_id: this.dadosPedido.razao,
            tabvnd_id: this.dadosPedido.tabvnd,
            pedido_id: this.dadosPedido.pedidoId,
          },
        };
      }
      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_COMPONENTES_EC}`;
        const { data } = await dpcAxios.connection().post(url, params);
        if (!data.error) {
          // await db.componente.clear();
          // this.componentes = [];
          data.componentes.forEach((componente, index) => {
            if (componente.tipo_id == 6) {
              data.componentes[index].isLoading = true;
              produtos_componentes.push(componente.componente_id);
            }

            db.componente
              .where("componente_id")
              .equals(componente.componente_id)
              .first()
              .then((componente_db) => {
                if (componente_db) {
                  db.componente.update(componente.componente_id, componente);
                } else {
                  db.componente.add(componente);
                }
              });
          });

          this.componentes = data.componentes;
          this.updateComponenteData();
          this.getComponentProdutos(produtos_componentes);
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    },

    montaVariavelDeProduto(value) {
      // Verifica se produto tem quantidade no carrinho
      if (this.$store.getters.carrinhoAtual.length) {
        const itemAdicionado = this.$store.getters.carrinhoAtual.find(
          (p) => p.id === value.produto_id
        );
        value.qtd = itemAdicionado ? itemAdicionado.qtd : 0;
      } else {
        value.qtd = 0;
      }

      const produto = {
        cod_produto: value.id,
        fornecedor: value.fornecedor_id,
        categoria: value.categoria_id,
        descricao: value.descricao,
        link: "./",
        src: value.src,
        valor: Number(value.vlrproduto),
        vlrstdesonerado: Number(value.vlrstdesonerado),
        vlrbruto: Number(
          value.vlrproduto + value.vlrst - value.vlrdesc - value.vlrstdesonerado
        ),
        vlrst: Number(value.vlrst),
        vlrdesconto: Number(value.vlrdesc),
        vlracresc: value.vlracresc,
        possuiestoque: value.possuiestoque,
        qtd: value.qtd,
        embqtdean: value.embqtdean,
        integracao_id: value.integracao_id,
        hasprodbloq: value.hasprodbloq,
        ean: value.ean,
        embeantext: value.embeantext,
        hascondbloq: value.hascondbloq,
        promocaomaxdesconto: value.promocaomaxdesconto || 0,
        haspromocaocampanha: value.haspromocaocampanha || false,
        promocao: value.promocao || null,
        grupo: value.grupo,
        displayDescontoPct: value.displayDescontoPct,
        decim: value.decim,
        decim_num_casas: value.decim_num_casas
      };

      return produto;
    },

    // Atualiza imagens dos componentes do site e produtos
    updateComponenteData() {
      this.componentes.map((componente) => {
        if (componente.imagens_validas.length > 0) {
          componente.imagens_validas.map((imagem, i) => {
            imagem.src = this.getImageSrc(imagem.tamanhos);

            if (componente.tipo_id == 1 && i == 0) {
              // console.log(image);
              const link = document.createElement("link");
              link.rel = "preload";
              link.as = "image";
              link.href = imagem.src;
              document.head.appendChild(link);
            }
            return imagem;
          });
        }
        if (componente.produtos && componente.produtos.length > 0) {
          const lista_produtos = [];
          componente.produtos.map((value) => {
            lista_produtos.push(this.montaVariavelDeProduto(value));
          });

          componente.produtos = lista_produtos;
        } else {
          componente.produtos = [];
        }
        return componente;
      });
      const { componentes } = this;
      this.componentes = [];
      this.componentes = componentes;
      this.isLoading = false;
    },
    getImageSrc(tamanhos) {
      let src = "";

      for (let i = 0; i < tamanhos.length; i++) {
        if (tamanhos[i].tipo == this.screen_atual) {
          src = `${tamanhos[i].nome_img}`;
          break;
        }
      }
      if (src == "") {
        switch (this.screen_atual) {
          case "D":
            tamanhos.forEach((tamanho) => {
              if (tamanho.tipo == "T") {
                src = `${tamanho.nome_img}`;
              }
            });
            if (src == "") {
              tamanhos.forEach((tamanho) => {
                if (tamanho.tipo == "M") {
                  src = `${tamanho.nome_img}`;
                }
              });
            }
            break;
          case "T":
            tamanhos.forEach((tamanho) => {
              if (tamanho.tipo == "M") {
                src = `${tamanho.nome_img}`;
              }
            });
            if (src == "") {
              tamanhos.forEach((tamanho) => {
                if (tamanho.tipo == "D") {
                  src = `${tamanho.nome_img}`;
                }
              });
            }
            break;

          case "M":
            tamanhos.forEach((tamanho) => {
              if (tamanho.tipo == "T") {
                src = `${tamanho.nome_img}`;
              }
            });
            if (src == "") {
              tamanhos.forEach((tamanho) => {
                if (tamanho.tipo == "D") {
                  src = `${tamanho.nome_img}`;
                }
              });
            }
            break;
          default:
            break;
        }
      }
      return src;
    },

    // ESTE EVENTO É DISPARADO QUANDO HA UMA MUDANÇA NO TAMANHO DA TELA.
    resize(event) {
      const width = $(window).width();
      if (width <= this.screen_xs) {
        this.screen_atual = "M";
      } else if (width <= this.screen_sm) {
        this.screen_atual = "T";
      } else {
        this.screen_atual = "D";
      }
      this.updateComponenteData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.section-all-components {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.parcelamento-info {
  user-select: none;
  h4 {
    font-size: 16px;
    color: #005a40;
    text-align: center;
    font-weight: 600;
  }
}
.slider-full-loading {
  min-height: 290px;
  background-color: $gray-lighter;
}

.parceiros-slider-loading {
  min-height: 100px;
  background: darken($gray-lighter, 3%) !important;
}

.banner-1-loading {
  min-height: 290px;
  background-color: $gray-lighter;
}

.session-header {
  color: $gray;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Montserrat";
}

#slider-produtos {
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: $gray-pale;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
}
</style>
