<template>
  <div
    v-if="showPagination && !paginateOnTop && total > 0"
    class="table-footer clearfix"
    :class="{ comprimido: total < perPage }"
  >
    <div class="datatable-length pull-left">
      <label v-show="total > perPage">
        <span class="por-pag-label">{{ rowsPerPageText }}</span>
        <span v-if="perPage" class="perpage-count">{{ perPage }}</span>
        <select class="form-control por-pag" @change="perPageChanged">
          <option
            v-for="option in getRowsPerPageDropdown()"
            v-bind:key="'rows-dropdown-option-' + option"
            :selected="
              (perPage && currentPerPage === option) ||
              currentPerPage === option
            "
            :value="option"
          >
            {{ option }}
          </option>
          <option value="-1" v-if="showAllConlumnsTable">{{ allText }}</option>
        </select>
      </label>
    </div>
    <div v-if="showQtdRowSelected" class="indice-paginacao left">
      <span>{{ qtdRowSelected }} {{ rowsSelectedText }}</span>
    </div>
    <div class="pagination-controls pull-right">
      <!-- <div class="info">{{paginatedInfo}}</div>
      <a href="javascript:undefined" class="page-btn" :class="{ disabled: !prevIsPossible }" @click.prevent.stop="previousPage" tabindex="0">
        <span class="chevron" v-bind:class="{ 'left': !rtl, 'right': rtl }"></span>
        <span>{{prevText}}</span>
      </a>
      <a href="javascript:undefined" class="page-btn" :class="{ disabled: !nextIsPossible }" @click.prevent.stop="nextPage" tabindex="0">
        <span>{{nextText}}</span>
        <span class="chevron" v-bind:class="{ 'right': !rtl, 'left': rtl }"></span>
      </a>-->
      <ul v-show="total > perPage" class="pagination">
        <li :class="{ disabled: !prevIsPossible }">
          <a class="btn-navigation" @click="previousPage">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </a>
        </li>
        <!-- <template>
                    <li v-for="page in noPages" :class="{active: page === currentPage}">
                        <a href="#" @click="(e) => goToPage(page, e)">{{page + 1}}</a>
                    </li>
        </template>-->
        <li :class="{ disabled: !nextIsPossible }">
          <a class="btn-navigation" @click="nextPage">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
      <div class="text-right indice-paginacao">{{ paginatedInfo }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VueGoodPagination",
  props: {
    paginateOnTop: {
      default: false,
      type: Boolean,
    },
    styleClass: { default: "table table-bordered", type: String },
    total: { default: 0, type: Number },
    perPage: { default: 0, type: Number },
    rtl: { default: false, type: Boolean },
    customRowsPerPageDropdown: {
      type: Array,
      default() {
        return [];
      },
    },

    // text options
    nextText: { default: "Next", type: String },
    prevText: { default: "Prev", type: String },
    rowsPerPageText: { default: "Rows per page:", type: String },
    rowsSelectedText: { default: "Rows Selected", type: String },
    ofText: { default: "of", type: String },
    allText: { default: "All", type: String },
    backHome: { default: false, type: Boolean },
    showQtdRowSelected: { default: false, type: Boolean },
    qtdRowSelected: { default: 0, type: Number },
    showAllConlumnsTable: { default: true, type: Boolean },
    changeTablePage: {
      default: "",
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPagination: { default: true, type: Boolean },
  },

  data: () => ({
    currentPage: 1,
    currentPerPage: 10,
    rowsPerPageOptions: [],
    defaultRowsPerPageDropdown: [10, 20, 30, 40, 50],
  }),

  computed: {
    paginatedInfo() {
      if (this.total == 0) {
        this.currentPage = 1;
        this.pageChanged();
        return `Página 1 - ${this.total} ${this.ofText} ${this.total}`;
      }
      if (this.currentPerPage === -1) {
        return `Página 1 - ${this.total} ${this.ofText} ${this.total}`;
      }
      const first =
        (this.currentPage - 1) * this.currentPerPage
          ? (this.currentPage - 1) * this.currentPerPage
          : 1;
      const last = Math.min(this.total, this.currentPerPage * this.currentPage);

      return `Página ${first} - ${last} ${this.ofText} ${this.total}`;
    },
    nextIsPossible() {
      return this.currentPerPage === -1
        ? false
        : this.total > this.currentPerPage * this.currentPage;
    },
    prevIsPossible() {
      return this.currentPage > 1;
    },
  },

  watch: {
    changeTablePage() {
      if (this.loading) {
        return false;
      }
      if (this.changeTablePage == "next") {
        this.nextPage();
      } else if (this.changeTablePage == "prev") {
        this.previousPage();
      }
    },
    total(newvalue, oldValue) {
      /* var atualizaCp = true;

                                        if (typeof oldValue == 'number' && typeof newvalue == 'number') {
                                          if (newvalue >= oldValue) {
                                            atualizaCp = false;
                                          }
                                        } */

      // if (atualizaCp) {
      this.currentPage = 1;
      this.currentPerPage = this.perPage;
      // }
    },

    perPage() {
      if (this.perPage > 0) {
        this.currentPerPage = this.perPage;
      } else {
        // reset to default
        this.currentPerPage = -1;
      }
      this.perPageChanged();
    },

    customRowsPerPageDropdown() {
      if (
        this.customRowsPerPageDropdown !== null &&
        Array.isArray(this.customRowsPerPageDropdown) &&
        this.customRowsPerPageDropdown.lenght !== 0
      )
        this.rowsPerPageOptions = this.customRowsPerPageDropdown;
    },

    backHome() {
      if (this.backHome) {
        this.backHomePage();
        this.currentPerPage = this.perPage;
        this.$emit("per-page-changed", { currentPerPage: this.currentPerPage });
      }
    },

    paginatedInfo() {
      this.emitPaginationInfo();
    },
  },

  mounted() {
    this.rowsPerPageOptions = this.defaultRowsPerPageDropdown;

    if (this.perPage) {
      this.currentPerPage = this.perPage;
      this.rowsPerPageOptions.push(this.perPage);
    }

    if (
      this.customRowsPerPageDropdown !== null &&
      Array.isArray(this.customRowsPerPageDropdown) &&
      this.customRowsPerPageDropdown.lenght !== 0
    )
      this.rowsPerPageOptions = this.customRowsPerPageDropdown;
  },
  activated() {
    this.emitPaginationInfo();
  },

  methods: {
    emitPaginationInfo() {
      var paginatedInfo = {
        quantidade: this.paginatedInfo,
        nextIsPossible: this.nextIsPossible,
        prevIsPossible: this.prevIsPossible,
      };
      this.$emit("infoPagination", paginatedInfo);
    },
    nextPage() {
      if (this.currentPerPage === -1) return;
      if (this.nextIsPossible) ++this.currentPage;
      this.pageChanged();
    },

    previousPage() {
      if (this.currentPage > 1) --this.currentPage;
      this.pageChanged();
    },

    pageChanged() {
      this.$emit("page-changed", { currentPage: this.currentPage });
    },

    perPageChanged(event) {
      if (event) {
        this.currentPerPage = parseInt(event.target.value);
      }

      // AO ALTERAR QUANTIDADE DE REGISTROS EXIBIDOS, VOLTA A PAGINA INICIAL
      this.backHomePage();

      this.$emit("per-page-changed", { currentPerPage: this.currentPerPage });
    },

    backHomePage() {
      this.currentPage = 1;
      this.pageChanged();
    },

    getRowsPerPageDropdown() {
      return this.rowsPerPageOptions;
    },
  },
};
</script>

<style lang="css" scoped>
/* Utility styles
                                    ************************************************/
.indice-paginacao {
  float: right;
  padding: 4px 6px;
  color: #666;

  font-weight: 600;
  font-size: 12px;
}

.indice-paginacao.left {
  float: left !important;
}

.por-pag {
  padding: 0px;
  font-weight: 600;
}

.btn-navigation {
  padding: 2px 8px;
  margin: 0px 1px;
}

.por-pag-label {
  font-weight: 600;
}

.pagination {
  float: right;
  margin: 0 !important;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

/* Table footer specific styles
                                    ************************************************/

.table-footer {
  /* background-color: rgba(35,41,53, 0.03); */
  background-color: rgba(35, 41, 53, 0.05);
  border: 1px solid #ddd;
  margin: 0;
  padding: 5px 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.44);
}

.comprimido {
  padding: 0px 10px;
}

.table-footer > div {
  display: inline-block;
}

.pagination-controls > * {
  display: inline-block;
}

.pagination-controls a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.66);
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8;
}

.pagination-controls a.disabled,
.pagination-controls a.disabled:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.pagination-controls a:hover {
  opacity: 1;
}

.pagination-controls a span {
  display: inline-block;
  vertical-align: middle;
}

.pagination-controls .info {
  margin: 0px 15px;
  font-size: 13px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}

.pagination-controls a .chevron {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  /* border:  1px solid rgba(35,41,53,0.2);
                                      background-color: #fff; */
  position: relative;
  margin: 0px 8px;
}

.pagination-controls .chevron::after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.pagination-controls .chevron.left::after {
  border-right: 6px solid rgba(0, 0, 0, 0.66);
  margin-left: -3px;
}

.pagination-controls .chevron.right::after {
  border-left: 6px solid rgba(0, 0, 0, 0.66);
  margin-left: -3px;
}

.table-footer select {
  display: inline-block;
  /* background-color: transparent; */
  width: auto;
  /* padding: 0; */
  /* border: 0; */
  /* border-radius: 0; */
  height: auto;
  font-size: 13px;
  margin-left: 8px;
  margin-bottom: 0px;
  /* color: rgba(0, 0, 0, 0.55); */
  /* font-weight: bold; */
}

.table-footer .perpage-count {
  color: rgba(0, 0, 0, 0.55);
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  /* on small screens hide the info */
  .pagination-controls .info {
    display: none;
  }
}
</style>
