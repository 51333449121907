import download from 'downloadjs';
import * as XLSX from 'xlsx/xlsx.mjs';

// mime type [xlsx, csv], default: xlsx
let type;
// Json to download
let data;
// fields inside the Json Object that you want to export
// if no given, all the properties in the Json are exported
let fields;
// Title for the data
let title;
// filename to export, default: data.xlsx
let name;
let meta;
let delimiter = ';';

export const generate = (options = {}) => {
    type = options.type;
    data = options.data;
    fields = options.fields;
    title = options.title;
    name = options.name || `data.${type === 'csv' ? 'csv' : 'xlsx'}`;
    meta = options.meta;
    delimiter = options.delimiter != undefined && options.delimiter != null ? options.delimiter : delimiter;

    if (!data.length) {
        return;
    }
    const json = getProcessedJson(data, fields);
    if (type === 'csv') {
        return exportt(jsonToCSV(json), name, "text/csv");
    }
    return exportt(jsonToXLSX(json), name, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

/*
Use downloadjs to generate the download link
*/
export const exportt = (data, filename, mime) => {
    const blob = new Blob([data], { type: mime });
    download(blob, filename, mime);
};

/*
jsonToXLSX
---------------
Transform json data into an Excel file (.xlsx) using the SheetJS library
*/
export const jsonToXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const xlsxData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return xlsxData;
};

/*
jsonToCSV
---------------
Transform json data into a CSV file.
*/
export const jsonToCSV = (data) => {
    let csvData = '';

    if (title != null) {
        if (Array.isArray(title)) {
            for (let i = 0; i < title.length; i++) {
                csvData += `${title[i]}\r\n`;
            }
        } else {
            csvData += `${title}\r\n`;
        }
    }

    for (const key in data[0]) {
        csvData += key + delimiter;
    }
    csvData = csvData.slice(0, csvData.length - 1);
    csvData += '\r\n';

    data.map((item) => {
        for (const key in item) {
            let value = formatNumber(item[key], key);
            if (typeof value === 'string' && value.match(/[,"\n]/)) {
                value = `"${value.replace(/\"/g, '""')}"`;
            }
            csvData += value + delimiter;
        }
        csvData = csvData.slice(0, csvData.length - 1);
        csvData += '\r\n';
    });
    return csvData;
};

/*
getProcessedJson
---------------
Get only the data to export, if no fields are set return all the data
*/
export const getProcessedJson = (data, header) => {
    const keys = getKeys(data, header);
    const newData = [];
    data.map((item) => {
        const newItem = {};
        for (const label in keys) {
            const property = keys[label];
            newItem[label] = getNestedData(property, item);
        }
        newData.push(newItem);
    });

    return newData;
};

export const getKeys = (data, header) => {
    if (header) {
        return header;
    }

    const keys = {};
    for (const key in data[0]) {
        keys[key] = key;
    }
    return keys;
};

export const callItemCallback = (field, itemValue) => {
    if (typeof field === 'object' && typeof field.callback === 'function') {
        return field.callback(itemValue);
    }

    return itemValue;
};

export const getNestedData = (key, item) => {
    const field = (typeof key === 'object') ? key.field : key;

    let valueFromNestedKey = null;
    const keyNestedSplit = field.split(".");

    valueFromNestedKey = item[keyNestedSplit[0]];
    for (let j = 1; j < keyNestedSplit.length; j++) {
        valueFromNestedKey = valueFromNestedKey[keyNestedSplit[j]];
    }

    valueFromNestedKey = callItemCallback(key, valueFromNestedKey);

    return valueFromNestedKey;
};

/*
formatNumber
---------------
Convert numeric values to a string with a comma as the decimal separator.
*/
export const formatNumber = (value, key) => {
    if (typeof value === 'number' && !key.toLowerCase().includes('ean') && !key.toLowerCase().includes('quantidade') ) {
        return value.toFixed(2).toString().replace('.', ',');
    }
    return value;
};
